<template>
	<div>
		<!-- STEP 1 (Type Account)-->
		<v-dialog
			v-if="step === 1"
			:value="value"
			persistent
			@input="$emit('input')"
			:content-class="'create_new_account_modal'"
			max-width="550">

			<div class="heading">
				<h2 class="heading_new_account">
					<template v-if="uses === 'accounts'">New Account</template>
					<template v-if="uses === 'createRelease'">Choose Account</template>
				</h2>
			</div>
			<div class="wrapper_content h165 v_center">
				<v-radio-group v-model="selectedTypeAccount" column> <!-- mandatory -->
					<v-radio value="Artist" color="#20C4F5" :ripple="false">
						<template v-slot:label>
							<div class="checkbox_label">
								Artist account
								<!--								<div class="hidden">- $0.99/mo</div>-->
								<div class="label_description">Upload and sell releases</div>
							</div>
						</template>
					</v-radio>
					<v-radio value="Label" color="#20C4F5" :ripple="false">
						<template v-slot:label>
							<div class="checkbox_label">
								Label account
								<!--								<div class="hidden">- $9.99/mo</div>-->
								<div class="label_description">Manage releases from various artists under one account</div>
							</div>
						</template>
					</v-radio>
				</v-radio-group>
			</div>
			<div class="wrapper_button center">
				<button type="button" class="tetriatary_btn" v-ripple @click="cancel">Cancel</button>

				<template v-if="selectedTypeAccount">
					<button v-if="uses === 'accounts'" type="button" class="tetriatary_btn" v-ripple @click="changeStep(2)">Next</button>
					<button v-if="uses === 'createRelease'" type="button" class="tetriatary_btn" v-ripple @click="changeStep(5)">Next</button>
				</template>
				<div v-else class="tetriatary_btn _button disabled">Next</div>

			</div>
		</v-dialog>

		<!-- STEP 2 (Subscription period)-->
		<v-dialog
			v-if="step === 2"
			:value="value"
			persistent
			@input="$emit('input')"
			:content-class="'create_new_account_modal'"
			max-width="550">

			<div class="heading">
				<h2 class="heading_new_account">
					{{ selectedTypeAccount }} Account
				</h2>
			</div>
			<div class="wrapper_content h165 v_center">
				<v-radio-group v-model="selectedSubscription" column mandatory>
					<v-radio value="monthly" color="#20C4F5" :ripple="false">
						<template v-slot:label>
							<div class="checkbox_label">
								Monthly subscription -
								<template v-if="selectedTypeAccount === 'Artist'">${{ commissionRates.artist_subscription_month }}/mo</template>
								<template v-if="selectedTypeAccount === 'Label'">${{ commissionRates.record_label_subscription_month }}/mo</template>
								<div class="label_description">Billed monthly</div>
							</div>
						</template>
					</v-radio>
					<v-radio value="yearly" color="#20C4F5" :ripple="false" :disabled="true">
						<template v-slot:label>
							<div class="checkbox_label">
								Yearly subscription -
								<template v-if="selectedTypeAccount === 'Artist'">${{ (commissionRates.artist_subscription_year / 12).toFixed(2) }}/mo
								</template>
								<template v-if="selectedTypeAccount === 'Label'">${{ (commissionRates.record_label_subscription_year / 12).toFixed(2) }}/mo
								</template>
								<div class="label_description">Billed yearly</div>
							</div>
						</template>
					</v-radio>

				</v-radio-group>
			</div>
			<div class="wrapper_button center">
				<button v-if="uses === 'accounts'" type="button" class="tetriatary_btn" v-ripple @click="changeStep(1)">Back</button>
				<button v-if="uses === 'createRelease'" type="button" class="tetriatary_btn" v-ripple @click="changeStep(5)">Back</button>
				<button v-if="uses === 'itemAccount'" type="button" class="tetriatary_btn" v-ripple @click="cancel">Cancel</button>

				<button type="button" class="tetriatary_btn" v-ripple @click="changeStep(3)" v-if="renewSubscription === false">Next</button>

				<template v-if="selectedTypeAccount === 'Artist'">
					<button type="button" class="tetriatary_btn" v-ripple @click="createNewArtistSubscription(selectedAccount)"
					        v-if="renewSubscription === true && (uses === 'createRelease' || uses === 'itemAccount')">Next
					</button>
				</template>

				<template v-if="selectedTypeAccount === 'Label'">
					<button type="button" class="tetriatary_btn" v-ripple @click="createNewArtistSubscription(selectedLabel)"
					        v-if="renewSubscription === true && (uses === 'createRelease' || uses === 'itemAccount')">Next
					</button>
				</template>
			</div>
			<div class="overlay custom_style_loader" v-if="createdLoader">
				<div class="progress_border">
					<v-progress-circular
						:size="250"
						:width="38"
						bg-color="rgba(255, 255, 255, 1)"
						color="#20C4F5"
						indeterminate
					></v-progress-circular>
				</div>
			</div>
		</v-dialog>

		<!-- STEP 3 (Info Account)-->
		<v-dialog
			v-if="step === 3"
			:value="value"
			persistent
			@input="$emit('input')"
			:content-class="'create_new_account_modal'"
			max-width="550">

			<div class="heading">
				<h2 class="heading_new_account">
					{{ selectedTypeAccount }} Account
				</h2>
			</div>
			<div class="wrapper_content">
				<div class="create_account_box">
					<div class="set_name_account">
						<div class="label_name">Choose a unique name</div>
						<input type="text" v-model.trim="accountName"
						       :class="{error_name: createArtistError || ($v.accountName.$dirty && $v.accountName.$error)}"
						       placeholder="Enter new account name">
						<span class="err_artist_exists" v-if="!uniqueArtistName && selectedTypeAccount === 'Artist'">
							Artist exists. Choose another Artist Name.
						</span>
						<span class="err_artist_exists" v-if="!uniqueLabelName && selectedTypeAccount === 'Label'">
							Label exists. Choose another Label Name.
						</span>
						<span class="err_artist_exists" v-if="$v.accountName.$dirty && !$v.accountName.maxLength">30 characters max</span>
						<span class="err_artist_exists" v-if="$v.accountName.$dirty && !$v.accountName.required">Account Name is required</span>
					</div>
					<div class="set_photo">
						<div class="label_photo">Profile photo</div>

						<div class="wrapper_photo">
							<div class="photo" @click="selectPhoto">
								<v-img
									v-if="selectedPhotoPreviewUrl"
									:src="selectedPhotoPreviewUrl"
									min-width="100%"
									min-height="100%"
								></v-img>
								<input
									type="file"
									multiple
									hidden
									ref="fileInput"
									accept="image/png, image/jpeg"
									autocomplete="off"
									@click="resetImageUploader"
									@change="photoSelected">
							</div>
							<div class="description_photo">
								<div><span>Size:</span>10 MB or smaller</div>
								<div><span>Format:</span>JPG, PNG</div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="wrapper_button center">
				<button type="button" class="tetriatary_btn" v-ripple @click="changeStep(2)">Back</button>
				<div class="tetriatary_btn _button disabled"
				     v-if="!accountName.length || (selectedTypeAccount === 'Artist' && !uniqueArtistName) || (selectedTypeAccount === 'Label' && !uniqueLabelName)">
					Next
				</div>
				<button type="button" v-else class="tetriatary_btn" v-ripple @click="createNewAccount">Next</button>
			</div>
			<div class="overlay custom_style_loader" v-if="createdLoader">
				<div class="progress_border">
					<v-progress-circular
						:size="250"
						:width="38"
						bg-color="rgba(255, 255, 255, 1)"
						color="#20C4F5"
						indeterminate
					></v-progress-circular>
				</div>
			</div>
		</v-dialog>

		<!-- STEP 4 (Card Account)-->
		<v-dialog
			v-if="step === 4"
			:value="value"
			persistent
			@input="$emit('input')"
			:content-class="'create_new_account_modal'"
			max-width="550">
			<form id="payment-form">
				<div class="heading">
					<h2 class="heading_new_account">
						{{ selectedTypeAccount }} Account
					</h2>
				</div>
				<div class="wrapper_content h450">

					<div class="info_card_box">
						<div class="account_data" v-if="!createdLoader">
							<div class="description_info">
								<div class="image" :class="{default_ava: !selectedPhotoPreviewUrl}">
									<v-img
										v-if="selectedPhotoPreviewUrl"
										:src="selectedPhotoPreviewUrl"
										min-width="100%"
										min-height="100%"
									></v-img>
									<div v-if="!selectedPhotoPreviewUrl" class="default_ava"></div>
								</div>
								<div class="name_type">
									<div class="name">{{ accountName }}</div>
									<div class="type">{{ selectedTypeAccount }}</div>
								</div>
							</div>
							<div class="price_info">
								<template v-if="selectedTypeAccount === 'Artist'">
									<template v-if="selectedSubscription === 'monthly'">${{ commissionRates.artist_subscription_month }}/mo</template>
									<template v-if="selectedSubscription === 'yearly'">${{ (commissionRates.artist_subscription_year / 12).toFixed(2) }}/mo
									</template>
								</template>
								<template v-if="selectedTypeAccount === 'Label'">
									<template v-if="selectedSubscription === 'monthly'">${{ commissionRates.record_label_subscription_month }}/mo</template>
									<template v-if="selectedSubscription === 'yearly'">
										${{ (commissionRates.record_label_subscription_year / 12).toFixed(2) }}/mo
									</template>
								</template>
							</div>
						</div>
						<div class="card_data">
							<StripeElementPayment
								v-if="stripeOptions.clientSecret"
								ref="paymentRef"
								:pk="stripeToken"
								:elements-options="stripeOptions"
								:confirm-params="confirmParams"
								:redirect="'if_required'"
								:load-complete="handleElementReady"
								@error="submitPaymentCallback"
								@success="submitPaymentCallback"
							></StripeElementPayment>
							<!--							@element-ready="handleElementReady"-->
							<!--							@paymentCompleted="handlePaymentCompleted"-->
							<!--<circleLoader :load-complete="loading === false" v-if="loading === true"></circleLoader>-->
						</div>
					</div>
				</div>
				<div class="wrapper_button center">
					<button type="button" class="tetriatary_btn" v-ripple @click="back" v-if="resolveSubscriptionMode === false">Back</button>
					<button type="button" class="tetriatary_btn" v-ripple @click="dialog = false" v-if="resolveSubscriptionMode === true">Close</button>

					<button type="button" class="tetriatary_btn pay" v-ripple @click="submitPayment" :class="{loading: submitStripeLoader}">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Next
					</button>
				</div>
				<div class="overlay custom_style_loader" v-if="createdLoader">
					<div class="progress_border">
						<v-progress-circular
							:size="250"
							:width="38"
							bg-color="rgba(255, 255, 255, 1)"
							color="#20C4F5"
							indeterminate
						></v-progress-circular>
					</div>
				</div>
			</form>
		</v-dialog>

		<!-- List Accounts, 2 -->
		<v-dialog
			v-if="step === 5"
			:value="value"
			persistent
			@input="$emit('input')"
			:content-class="'user-custom-modal'"
			max-width="550">

			<div class="heading">
				<h2 class="heading_new_account">
					<template v-if="uses === 'createRelease'">Upload to {{ selectedTypeAccount }}</template>
					<template v-if="uses === 'accounts'">Accounts</template>
				</h2>
				<button class="close_modal" @click="dialog = false"></button>
			</div>
			<div class="wrapper_content" :class="{'active': showBlockCreate, 'is_error': showBlockCreate && (createArtistError || !$v.accountName.maxLength)}">
				<div class="box_create_button" @click="showCreateBox">
					<button type="button" :class="{'active': showBlockCreate}"></button>
					<div>
						Create new account
						<span>Click here to create a new artist or label</span>
					</div>
				</div>
			</div>

			<template v-if="selectedTypeAccount === 'Artist'">
				<div v-if="artists && artists.length" class="list_accounts"
				     :class="{'active': showBlockCreate, 'is_error': showBlockCreate && (createArtistError || !$v.accountName.maxLength)}">
					<div class="item_account" v-for="account of artists" :key="account.id"
					     @click="selectAccount(account); redirectToArtistPage(account.id, account.identifier, false)">
						<div class="data">
							<div class="img">
								<img v-if="account.avatar && account.avatar.contentUrl" :src="apiUrl.slice(0, -1) + account.avatar.contentUrl" alt="">
								<div class="default_user_avatar" v-else></div>
							</div>
							<div class="description">
								<div class="name">{{ account.name }}</div>
								<div class="type" v-if="account.hasActiveSubscription === true">{{ account['@type'] }}</div>

								<SubscriptionStatus v-if="hasRestrictedStatus(account)" :subscription="account.subscription"/>
							</div>
						</div>
						<div class="action" :class="`${uses}`">
							<template v-if="uses === 'createRelease'">
								<div class="check_round" :tabindex="4" :class="{checked: selectedAccount && selectedAccount.id === account.id}"></div>
							</template>
							<template v-if="uses === 'accounts'">
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									:nudge-width="80"
									offset-y
									nudge-left="40"
									nudge-bottom="5"
									origin="top center"
									content-class="br20 accounts_modal">

									<template v-slot:activator="{ on, attrs }">
										<button type="button" v-ripple class="more_btn" v-bind="attrs" v-on="on"></button>
									</template>

									<v-list>
										<v-list-item v-ripple>
											<div @click="redirectToArtistPage(account.id, account.identifier, true)">Edit</div>
										</v-list-item>
										<v-list-item v-ripple>
											<button type="button" @click="deleteArtistAccount(account)" class="delete">Delete</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</template>
						</div>
					</div>
				</div>
			</template>
			<template v-if="selectedTypeAccount === 'Label'">
				<div class="list_accounts" v-if="recordLabels && recordLabels.length">
					<div class="item_account" v-for="label of recordLabels" :key="label.id" @click="selectRecordLabel(label)">
						<div class="data">
							<div class="img">
								<img v-if="label.logo && label.logo.contentUrl" :src="apiUrl.slice(0, -1) + label.logo.contentUrl" alt="">
								<div class="default_user_avatar" v-else></div>
							</div>
							<div class="description">
								<div class="name">{{ label.title }}</div>
								<div class="type restricted" v-if="!label.hasActiveSubscription">Restricted</div>
							</div>
						</div>
						<div class="action" :class="`${uses}`">
							<!--							{{selectedAccount.id}}/-->
							<!--							{{selectedLabel}}-->
							<template v-if="uses === 'createRelease'">
								<div class="check_round" :tabindex="4" :class="{checked: selectedLabel && selectedLabel.id === label.id}"></div>
							</template>
							<template v-if="uses === 'accounts'">
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									:nudge-width="80"
									offset-y
									nudge-left="40"
									nudge-bottom="5"
									origin="top center"
									content-class="br20 accounts_modal">

									<template v-slot:activator="{ on, attrs }">
										<button type="button" v-ripple class="more_btn" v-bind="attrs" v-on="on"></button>
									</template>

									<v-list>
										<v-list-item v-ripple>
											<div @click="redirectToLabelProfile(label.id, label.title, true)">Edit</div>
										</v-list-item>
										<v-list-item v-ripple>
											<button type="button" @click="deleteLabelAccount(label)" class="delete">Delete</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</template>
						</div>
					</div>
				</div>
			</template>


			<div class="wrapper_button center">
				<button type="button" class="tetriatary_btn" @click="changeStep(1)">Back</button>
				<button v-if="selectedTypeAccount === 'Artist'" type="button" class="done" @click="done('artist')" v-ripple :tabindex="5"
				        :class="{disabled: !selectedAccount}">Next
				</button>
				<button v-if="selectedTypeAccount === 'Label'" type="button" class="done" @click="done('label')" v-ripple :tabindex="5"
				        :class="{disabled: !selectedLabel}">Next
				</button>
			</div>
			<div class="overlay custom_style_loader" v-if="getAccountsLoader">
				<div class="progress_border">
					<v-progress-circular
						:size="250"
						:width="38"
						bg-color="rgba(255, 255, 255, 1)"
						color="#20C4F5"
						indeterminate
					></v-progress-circular>
				</div>
			</div>
			<deleteAccount v-if="modalDeleteAccount"
			               v-model="modalDeleteAccount"
			               :deleteAccount="prepareDeleteAccount"
			               :accountReleases="artistReleases">
			</deleteAccount>
		</v-dialog>

		<!--  Account Issue -->
		<v-dialog
			v-if="accountIssueDialog"
			:value="value"
			persistent
			@input="$emit('input')"
			:content-class="'create_new_account_modal'"
			max-width="550">

			<div class="heading">
				<h2 class="heading_new_account">
					Account Issue
				</h2>
			</div>
			<div class="wrapper_content h165">
				<template v-if="selectedTypeAccount === 'Artist'">
					<div class="account_issue_box" v-if="!hasExpiresStatus(selectedAccount)">
						Your subscription for <span>{{ selectedAccount.name }}</span> has expired. Please renew your subscription to resolve the issue.
					</div>
					<div class="account_issue_box" v-if="hasExpiresStatus(selectedAccount)">
						Please renew your account to continue.
					</div>
				</template>
				<template v-if="selectedTypeAccount === 'Label'">
					<div class="account_issue_box" v-if="!hasExpiresStatus(selectedLabel)">
						Your subscription for <span>{{ selectedLabel.title }}</span> has expired. Please renew your subscription to resolve the issue.
					</div>
					<div class="account_issue_box" v-if="hasExpiresStatus(selectedLabel)">
						Please renew your account to continue.
					</div>
				</template>
			</div>
			<div class="wrapper_button center">
				<button type="button" class="tetriatary_btn" v-ripple @click="accountIssueDialog = false" v-if="uses !== 'itemAccount'">Back</button>
				<button type="button" class="tetriatary_btn" v-ripple @click="cancel" v-if="uses === 'itemAccount'">Cancel</button>
				<template v-if="selectedTypeAccount === 'Artist'">
					<button type="button" class="tetriatary_btn" v-ripple @click="resolveSubscription(selectedAccount)" :class="{loading: createdLoader}">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Renew
					</button>
				</template>
				<template v-if="selectedTypeAccount === 'Label'">
					<button type="button" class="tetriatary_btn" v-ripple @click="resolveSubscription(selectedLabel)" :class="{loading: createdLoader}">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Renew
					</button>
				</template>

			</div>
		</v-dialog>

		<!-- photo Limit dialog -->
		<v-dialog v-if="photoLimitDialog"
		          v-model="photoLimitDialog"
		          persistent
		          max-width="600">
			<div class="need_login_dialog style_dialog">
				<h2 class="header">
					Oops!
				</h2>
				<div class="cnt">
					Please choose an image that's less than 10MB.
				</div>
				<div class="wrap_actions center">
					<button type="button" class="cancel" @click="photoLimitDialog = false" v-ripple>Ok</button>
				</div>
			</div>
		</v-dialog>

		<!-- Success -->
		<v-dialog v-if="step === 6" :value="value"
		          :content-class="'create_new_account_modal'"
		          max-width="550">
			<div>
				<div class="heading">
					<h2 class="heading_new_account">
						New
						<template v-if="selectedTypeAccount === 'Artist'">Artist</template>
						<template v-if="selectedTypeAccount === 'Label'">Label</template>
						Account
					</h2>
				</div>
				<div class="wrapper_content h450">
					<div class="info_card_box">
						<div class="card_data">
							<div class="success_status">
								<div class="status">Success!</div>
								<div class="image_status"></div>
								<div class="description_status">Manage subscriptions in <i>Account Settings -> Subscriptions</i></div>
							</div>
						</div>
					</div>
				</div>
				<div class="wrapper_button center">
					<div class="tetriatary_btn _button" v-ripple @click="doneAfterCreated">Done</div>
				</div>
			</div>
		</v-dialog>
		<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>

		<!-- Choose Structure -->
		<!-- if selected record-label -->
		<v-dialog v-if="step === 12"
		          :value="value"
		          @input="$emit('input')"
		          persistent
		          :content-class="'create_new_account_modal'"
		          max-width="550">

			<div>
				<div class="heading">
					<h2 class="heading_new_account">
						Choose Structure {{chooseArtistStructure}}
					</h2>
				</div>
				<div class="wrapper_content h165 v_center">
					<v-radio-group v-model="chooseArtistStructure" column> <!-- mandatory -->
						<v-radio value="one" color="#20C4F5" :ripple="false">
							<template v-slot:label>
								<div class="checkbox_label">
									One artist

									<div class="label_description">One main artist and collaboration on tracks</div>
								</div>
							</template>
						</v-radio>
						<v-radio value="various" color="#20C4F5" :ripple="false">
							<template v-slot:label>
								<div class="checkbox_label">
									Various artists
									<div class="label_description">A compilation of tracks from different artists</div>
								</div>
							</template>
						</v-radio>
					</v-radio-group>
				</div>
				<div class="wrapper_button center">
					<button type="button" class="tetriatary_btn" v-ripple @click="dialog = false">Cancel</button>

					<template v-if="selectedTypeAccount && chooseArtistStructure">

						<!-- v-if="uses === 'createRelease'" -->
<!--						<button v-if="chooseArtistStructure === 'various'" type="button" class="tetriatary_btn" v-ripple @click="dialog = false;">Next</button>-->
						<button type="button" class="tetriatary_btn" v-ripple @click="chooseArtistStructureNext">Next</button>

					</template>
					<div v-else class="tetriatary_btn _button disabled">Next</div>

				</div>
			</div>

		</v-dialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import deleteAccount from "@/components/dialogs/deleteAccount";
import {StripeElementPayment} from '@vue-stripe/vue-stripe';

import errorDialog from "@/components/dialogs/error.vue";
import SubscriptionStatus from "@/components/subscriptionStatus.vue";

export default {
	name: "newAccount",
	props: [
		'value',
		'uses',   // createRelease || accounts
		'firstShowModal',
		'restrictedAccount',
		'doneModal',
		'viewListAccounts' // artist || label
	],
	components: {
		SubscriptionStatus,
		deleteAccount,
		StripeElementPayment,
		errorDialog
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			stripeToken: process.env.VUE_APP_STRIPE_API_TOKEN,
			accountName: '',
			typeAccount: 'Artist',
			showBlockCreate: false,
			createdLoader: false,
			submitStripeLoader: false,
			getAccountsLoader: false,

			selectedAccount: null,

			selectedLabelId: null,
			selectedLabel: null,

			modalDeleteAccount: false,
			prepareDeleteAccount: null,

			step: 1,
			selectedTypeAccount: null,
			selectedSubscription: null,

			errorDialog: false,

			stripeOptions: {
				appearance: {
					variables: {
						fontSizeBase: '16px',
						colorText: '#ffffff',
						colorDanger: '#E94E4E',
						borderRadius: '5px',
						colorBackground: '#0C1324',
						colorIconTabSelected: '#ffffff',
					},
					rules: {
						'.Tab': {
							padding: '12px 12px 7px 12px',
							border: 'none',
							backgroundColor: '#182034',
							boxShadow: 'none'
						},
						'.Tab--selected, .Tab--selected:focus': {
							border: '1px solid #5F6986',
							boxShadow: 'none',
						},
						'.TabLabel': {
							color: '#ffffff',
						},
						'.Input': {
							boxShadow: 'none',
						},
						'.Input--invalid': {
							boxShadow: 'none',
							border: '1px solid #E94E4E'
						},
						'.Input:focus': {
							boxShadow: 'none',
							border: '1px solid #5F6986'
						},

						'.Input::placeholder': {
							color: '#7481A6',
						},
						'.Error': {
							fontSize: '12px',
							marginLeft: '5px'
						}
					}
				},
			},
			stripeElements: '',
			confirmParams: {
				return_url: `${window.location.protocol}//${window.location.host}/dashboard/accounts`,
			},

			// photo
			selectedFile: null,
			selectedPhotoPreviewUrl: null,
			photoLimitDialog: false,

			accountIssueDialog: false,
			renewSubscription: false,

			successDialog: false,
			tempCreatedArtistName: null,
			tempCreatedLabelTitle: null,
			showPaymentModal: false,

			chooseArtistStructure: null,
			variousArtists: false,

			resolveSubscriptionMode: false,
		}
	},
	validations: {
		accountName: {
			required,
			minLength: minLength(1),
			maxLength: maxLength(30)
		}
	},
	filters: {
		reverse: (array) => {
			return array.reverse();
		}
	},
	watch: {
		dialog() {
			if (this.dialog === false) {
				this.showBlockCreate = false;
			}
		},
		// chooseArtistStructure() {
		// 	if (this.chooseArtistStructure === 'various') {
		// 		this.$store.commit('SET_VARIOUS_ARTISTS', true);
		// 		this.$store.commit('SET_SELECT_ARTIST', null);
		// 	} else {
		// 		this.$store.commit('SET_VARIOUS_ARTISTS', false);
		// 	}
		// },
		// accountName() {
		// 	this.$store.commit('SET_CREATE_ARTIST_ERROR', false);
		// },
		showBlockCreate() {
			if (this.showBlockCreate === false) {
				this.$store.commit('SET_CREATE_ARTIST_ERROR', false);
			}
		},
		step() {
			this.$v.$reset();
		},
		selectedTypeAccount() {
			this.accountName = '';
		},
		accountName() {
			if (this.selectedTypeAccount === 'Artist') {
				let formData = new FormData();
				formData.append('name', this.accountName);
				this.checkUniqueArtistName(formData)
					.catch(err => console.log(`checkUniqueArtistName, ${err}`));
			}
			if (this.selectedTypeAccount === 'Label') {
				let formData = new FormData();
				formData.append('title', this.accountName);
				this.checkUniqueLabelName(formData)
					.catch(err => console.log(`checkUniqueLabelName, ${err}`));
			}
		},
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			isAdmin: 'isAdmin',
			artists: 'YOUR_ARTISTS',
			createArtistError: 'CREATE_ARTIST_ERROR',
			uniqueArtistName: 'UNIQUE_ARTIST_NAME',
			SELECT_ARTIST: 'SELECT_ARTIST',

			commissionRates: 'COMMISSION_RATES',

			artistReleases: 'ARTIST_ALBUMS',
			subscriptionClientSecret: 'SUBSCRIPTION_CLIENT_SECRET',
			UNCANCEL_ARTIST_SUBSCRIPTION_STATUS: 'UNCANCEL_ARTIST_SUBSCRIPTION_STATUS',

			// RecordLabel
			recordLabels: 'YOUR_RECORD_LABELS',
			createRecordLabelError: 'CREATE_RECORD_LABEL_ERROR',
			recordLabelClientSecret: 'RECORD_LABEL_CLIENT_SECRET',
			uniqueLabelName: 'UNIQUE_LABEL_NAME',
			selectedLabelStore: 'SELECTED_LABEL',


			// CENTRIFUGE dependencies
			authData: 'AUTH_DATA',
			centrifuge: 'CENTRIFUGE_CLIENT',
		}),
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		},

	},
	created() {
		if (!this.isAdmin) {
			this.$router.push({name: "404"});
			return;
		}


		if (this.restrictedAccount) {
			if (this.restrictedAccount.name) {
				this.selectedTypeAccount = 'Artist';
			} else {
				this.selectedTypeAccount = 'Label';
			}
		}

		if (this.selectedLabelStore) {
			this.step = 12;
			this.selectedTypeAccount = 'Artist';
		} else {
			if (this.viewListAccounts) {
				this.step = 5;
				this.selectedTypeAccount = this.viewListAccounts;
			}
		}
	},
	mounted() {
		this.getCommissionRates()
			.catch(err => {
				this.errorDialog = true;
				console.error(`getCommissionRates, ${err}`);
			});

		if (this.uses !== 'accounts' && this.artists && !this.artists.length) {
			this.getArtistList();
		}
		if (this.uses !== 'accounts' && this.recordLabels && !this.recordLabels.length) {
			this.getLabels();
		}

		this.$store.commit('SET_CREATE_ARTIST_ERROR', false);

		if (this.firstShowModal === 'account-issue') {
			this.selectedAccount = this.restrictedAccount;
			this.accountIssueDialog = true;
		}
	},
	methods: {
		...mapActions({
			getArtists: 'GET_YOUR_ARTISTS',
			createArtist: 'CREATE_ARTIST',
			getArtistReleases: 'GET_ARTIST_ALBUMS',
			checkUniqueArtistName: 'CHECK_UNIQUE_ARTIST_NAME',

			getCommissionRates: 'GET_COMMISSION_RATES',

			// artist subscription
			unCancelArtistSubscription: 'UNCANCEL_ARTIST_SUBSCRIPTION',
			createArtistSubscription: 'CREATE_ARTIST_SUBSCRIPTION',
			renewArtistSubscription: 'RENEW_ARTIST_SUBSCRIPTION',

			// record label subscription
			createRecordLabelSubscription: 'CREATE_RECORD_LABEL_SUBSCRIPTION',
			renewRecordLabelSubscription: 'RENEW_RECORD_LABEL_SUBSCRIPTION',
			unCancelRecordLabelSubscription: 'UNCANCEL_RECORD_LABEL_SUBSCRIPTION',

			// RecordLabel
			getRecordLabels: 'GET_YOUR_RECORD_LABELS',
			// getRecordLabelLoader: 'YOUR_RECORD_LABELS_LOADER',
			createRecordLabel: 'CREATE_RECORD_LABEL',
			checkUniqueLabelName: 'CHECK_UNIQUE_LABEL_NAME',

			// CENTRIFUGE client
			getCentrifuge: 'GET_CENTRIFUGE_CLIENT',
		}),
		handlePaymentCompleted() {
			console.log('handlePaymentCompleted!!!');
			this.submitStripeLoader = false;
			this.step = 6;
		},
		handleElementReady() {
			console.log('handleElementReady!!!');
		},
		redirectToArtistPage(artistId, artistName, edit) {
			if (this.uses === 'accounts') {
				// const name = artistName.trim().replace(/ /g, '-');
				this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName), edit: edit}});
			}
		},
		redirectToLabelProfile(labelId, labelTitle, edit) {
			// const name = labelTitle.trim().replace(/ /g, '-');
			this.$router.push({name: 'label-profile', params: {id: labelId, name: window.toHumanFormat(labelTitle), edit: edit}});
		},

		showCreateBox() {
			this.step = 2;
		},

		getArtistList() {
			this.getAccountsLoader = true;
			this.getArtists({
				owner: this.userID,
				deletedAt: false,
				'order[createdAt]': 'desc'
			})
				.then(() => {
					if (this.tempCreatedArtistName) {
						this.selectedAccount = this.artists.find(artist => artist.name.trim() === this.tempCreatedArtistName.trim());
					}
				})
				.catch(err => console.error(`getArtists, getArtists, ${err}`))
				.finally(() => {
					this.getAccountsLoader = false;
				});
		},
		getLabels() {
			this.getAccountsLoader = true;
			this.getRecordLabels({
				owner: this.userID,
				sort: 'createdAt',
				order: 'desc'
			})
				.then(() => {
					if (this.tempCreatedLabelTitle) {
						this.selectedLabel = this.recordLabels.find(label => label.title.trim() === this.tempCreatedLabelTitle.trim());
					} else {
						console.log('need this.tempCreatedLabelTitle');
					}
				})
				.catch(err => console.log(`getLabels, getRecordLabels, ${err}`))
				.finally(() => {
					this.getAccountsLoader = false;
				})
		},

		getCentrifuges(channel, callback) {
			this.getCentrifuge()
				.then(() => {
					const self = this;
					const sub = this.centrifuge.newSubscription(`${channel}${this.authData.userId}`);

					sub.on('publication', function (ctx) {
						if (ctx.data.status === 'success') {
							callback(self);
						}
						self.submitStripeLoader = false;
					}).subscribe();

					this.centrifuge.connect();
				})
				.catch(err => {
					console.error('CentrifugeError', err);
				})
				.finally(() => {
					this.submitStripeLoader = false;
				})
		},

		// step 3 -> 4
		async createNewAccount() {
			this.$v.accountName.$touch();
			if (this.accountName) {
				this.createdLoader = true;

				let formData = new FormData();

				// this.selectedFile = event.target.files[0];
				const limitImageSize = 10 * 1024 * 1024;

				if (this.selectedTypeAccount === 'Artist') {
					formData.append('name', this.accountName);
				}
				if (this.selectedTypeAccount === 'Label') {
					formData.append('title', this.accountName);
				}

				if (this.selectedFile && (this.selectedFile.type === "image/jpeg" || this.selectedFile.type === "image/png") && this.selectedFile.size < limitImageSize) {
					if (this.selectedTypeAccount === 'Artist') {
						formData.append('avatar', this.selectedFile);
					}
					if (this.selectedTypeAccount === 'Label') {
						formData.append('logo', this.selectedFile);
					}
				}

				// create artist
				if (this.selectedTypeAccount === 'Artist') {
					await this.createArtist(formData)
						.then(() => {
							if (!this.createArtistError) {
								if (this.subscriptionClientSecret) {
									localStorage.setItem('createdArtistName', this.accountName);
									this.stripeOptions.clientSecret = this.subscriptionClientSecret;
									this.changeStep(4);
									// this.createdLoader = false;
								}

								// waiting event of successful creation of a new artist with subscription.
								this.getCentrifuges('create_artist_', function (self) {
									self.changeStep(6);
								});
							} else {
								this.createdLoader = false;
							}
						})
						.catch(err => {
							console.error(`create artist account, ${err}`);
						})
						.finally(() => {
							setTimeout(() => {
								this.createdLoader = false;
							}, 120)
						})
				}

				// create label
				if (this.selectedTypeAccount === 'Label') {
					await this.createRecordLabel(formData)
						.then(() => {
							console.log('createRecordLabel');
							if (!this.createRecordLabelError) {
								console.log('!this.createRecordLabelError');
								if (this.recordLabelClientSecret) {
									console.log('this.accountName', this.accountName, this.recordLabelClientSecret);
									localStorage.setItem('createdLabelTitle', this.accountName);
									this.stripeOptions.clientSecret = this.recordLabelClientSecret;
									this.changeStep(4);
								}

								// waiting event of successful creation of a new record label with subscription.
								this.getCentrifuges('create_record_label_', function (self) {
									self.changeStep(6);
								});
							} else {
								this.createdLoader = false;
							}
						})
						.catch(err => {
							console.error(`create label account, ${err}`)
						})
						.finally(() => {
							setTimeout(() => {
								this.createdLoader = false;
							}, 120)
						})
				}

			}
		},
		doneAfterCreated() {
			this.successDialog = false;

			if (this.selectedTypeAccount === 'Artist') {
				this.getArtistList();
			}
			if (this.selectedTypeAccount === 'Label') {
				this.getLabels();
			}


			if (this.selectedTypeAccount === 'Artist') {
				this.$store.commit('manageAccounts/SET_TOP_FILTER', 'artist');
				if (this.uses === 'createRelease') {
					this.tempCreatedArtistName = localStorage.getItem('createdArtistName');
					localStorage.removeItem('createdArtistName');
				}
			}
			if (this.selectedTypeAccount === 'Label') {
				this.$store.commit('manageAccounts/SET_TOP_FILTER', 'label');
				if (this.uses === 'createRelease') {
					this.tempCreatedLabelTitle = localStorage.getItem('createdLabelTitle');
					localStorage.removeItem('createdLabelTitle');
				}
			}

			this.dialog = false;

			if (this.uses === 'createRelease') {
				this.step = 5;
				this.dialog = true;
			} else {
				this.selectedTypeAccount = null;
				this.dialog = false;
			}
		},


		deleteArtistAccount(artist) {
			this.prepareDeleteAccount = artist;
			this.modalDeleteAccount = true;
			this.getArtistReleases({artist: this.prepareDeleteAccount.id})
				.then(() => {

				})
				.catch(err => {
					console.error(`deleteArtistAccount - getArtistReleases, ${err}`);
				})
		},
		deleteLabelAccount(label) {

		},

		selectAccount(account) {
			if (this.uses === 'createRelease') {
				this.selectedAccount = account;
				this.accountName = account.name;

				if (account.avatar) {
					this.selectedPhotoPreviewUrl = this.apiUrl + account.avatar.contentUrl;
				}
				if ((!account.hasActiveSubscription && !account.subscription) || (!account.hasActiveSubscription && account.subscription && !account.subscription.canActivate)) {
					this.accountIssueDialog = true;
				} else {
					this.selectedAccount = account;
				}
			}
		},
		selectRecordLabel(label) {
			if (this.uses === 'createRelease') {
				// this.$store.commit('SET_SELECTED_LABEL', label);
				this.selectedLabel = label;
				this.selectedLabelId = label.id;
				if (label.logo) {
					this.selectedPhotoPreviewUrl = this.apiUrl + label.logo.contentUrl;
				}
				if ((!label.hasActiveSubscription && !label.subscription) || (!label.hasActiveSubscription && label.subscription && !label.subscription.canActivate)) {
					this.accountIssueDialog = true;
				} else {
					this.selectedLabelId = label.id;
				}
			}
		},

		done(el) {
			if (el === 'artist') {
				if (this.selectedAccount?.hasActiveSubscription) {
					this.$store.commit('SET_SELECT_ARTIST', this.selectedAccount);
					this.dialog = false;
				} else if (!this.selectedAccount?.hasActiveSubscription) {
					this.accountIssueDialog = true;
				}
			}
			if (el === 'label') {
				if (this.selectedLabel?.hasActiveSubscription) {
					this.$store.commit('SET_SELECTED_LABEL', this.selectedLabel);
					this.$store.commit('manageAccounts/SET_TOP_FILTER', 'label');
					this.dialog = false;
				} else if (!this.selectedLabel?.hasActiveSubscription) {
					this.accountIssueDialog = true;
				}
			}
		},
		cancel() {
			this.selectedTypeAccount = null;
			this.dialog = false;
		},

		// step-12
		chooseArtistStructureNext() {
			this.step = 5;
			if (this.chooseArtistStructure === 'various') {
				this.$store.commit('SET_VARIOUS_ARTISTS', true);
				this.$store.commit('SET_SELECT_ARTIST', null);
				this.dialog = false;
			} else {
				this.$store.commit('SET_VARIOUS_ARTISTS', false);
			}
		},

		changeStep(step) {
			this.step = step;
		},
		back() {
			if (this.renewSubscription === true) {
				this.step = 2;
			} else {
				this.step = 3;
			}
		},

		createNewArtistSubscription(account) {
			this.createdLoader = true;

			if (account.name) {
				this.createArtistSubscription(account.id)
					.then(() => {
						if (this.subscriptionClientSecret) {
							this.stripeOptions.clientSecret = this.subscriptionClientSecret;
							this.changeStep(4);
							this.accountIssueDialog = false;
						}

						// waiting for an event of successful payment and updating the list of artists.
						this.getCentrifuges('renew_artist_subscription', function (self) {
							account.hasActiveSubscription = true;
							account.subscription.status = 'active';
							account.subscription.issue = {};

							self.changeStep(6);
						});
					})
					.catch(err => console.error(`createArtistSubscription, ${err}`))
					.finally(() => {
						this.createdLoader = false;
					});
			}
			if (account.title) {
				this.createRecordLabelSubscription(account.id)
					.then(() => {
						if (this.recordLabelClientSecret) {
							this.stripeOptions.clientSecret = this.recordLabelClientSecret;
							this.changeStep(4);
							this.accountIssueDialog = false;
						}

						// waiting for an event of successful payment and updating the list of artists.
						this.getCentrifuges('renew_record_label_subscription', function (self) {
							account.hasActiveSubscription = true;
							account.subscription.status = 'active';
							account.subscription.issue = {};

							self.changeStep(6);
						});
					})
					.catch(err => console.error(`createRecordLabelSubscription, ${err}`))
					.finally(() => {
						this.createdLoader = false;
					});
			}

		},

		resolveSubscription(account) {
			this.createdLoader = true;
			this.resolveSubscriptionMode = true;

			// Select Account
			this.selectedAccount = account;
			this.accountName = account.name || account.title;

			if (account.name) {
				this.selectedTypeAccount = 'Artist';
			} else {
				this.selectedTypeAccount = 'Label';
			}

			this.selectedSubscription = 'monthly';

			if (account.avatar || account.logo) {
				this.selectedPhotoPreviewUrl = this.apiUrl + account.avatar.contentUrl;
			}


			if (account.subscription && account.subscription.issue && account.subscription.issue.type === 'cancel_expires') {

				// Un-Cancel subscription
				if (this.selectedTypeAccount === 'Artist') {
					let params = {
						artistID: account.id,
						subscriptionID: account.subscription?.id,
					}

					this.unCancelArtistSubscription(params)
						.then(() => {
							this.accountIssueDialog = false;
							account.hasActiveSubscription = true;
							account.subscription.status = 'active';
							account.subscription.issue = {};
						})
						.catch(err => {
							console.error(`unCancelArtistSubscription, ${err}`);
						})
						.finally(() => {
							this.createdLoader = false;
						})

				} else if (this.selectedTypeAccount === 'Label') {
					let params = {
						labelID: account.id,
						subscriptionID: account.subscription?.id,
					}

					this.unCancelRecordLabelSubscription(params)
						.then(() => {
							this.accountIssueDialog = false;
							account.hasActiveSubscription = true;
							account.subscription.status = 'active';
							account.subscription.issue = {};
						})
						.catch(err => {
							console.error(`unCancelRecordLabelSubscription, ${err}`);
						})
						.finally(() => {
							this.createdLoader = false;
						})
				}
			}

			if (!account.subscription || (account.subscription && (account.subscription.status === 'incomplete' || account.subscription.status === 'incomplete_expired'))) {
				// new subscription
				this.createNewArtistSubscription(account);
			} else {
				// Renew subscription
				if (this.selectedTypeAccount === 'Artist') {
					let params = {
						artistID: account.id,
						subscriptionID: account.subscription?.id,
					}
					this.renewArtistSubscription(params)
						.then(() => {
							if (this.subscriptionClientSecret) {
								this.stripeOptions.clientSecret = this.subscriptionClientSecret;
								this.changeStep(4);
								this.accountIssueDialog = false;

								// waiting for an event of successful payment and updating the list of artists.
								this.getCentrifuges('renew_artist_subscription', function (self) {
									account.hasActiveSubscription = true;
									account.subscription.status = 'active';
									account.subscription.issue = {};

									self.changeStep(6);
								});
							}
						})
						.catch(err => {
							console.error(`artist renewArtistSubscription, ${err}`)
						})
						.finally(() => {
							this.createdLoader = false;
						})
				}
				if (this.selectedTypeAccount === 'Label') {
					let params = {
						labelID: account.id,
						subscriptionID: account.subscription?.id,
					}
					this.renewRecordLabelSubscription(params)
						.then(() => {
							if (this.recordLabelClientSecret) {
								this.stripeOptions.clientSecret = this.recordLabelClientSecret;
								this.changeStep(4);
								this.accountIssueDialog = false;

								// waiting for an event of successful payment and updating the list of artists.
								this.getCentrifuges('renew_record_label_subscription', function (self) {
									account.hasActiveSubscription = true;
									account.subscription.status = 'active';
									account.subscription.issue = {};

									self.changeStep(6);
								});
							}
						})
						.catch(err => {
							console.error(`label renewRecordLabelSubscription, ${err}`)
						})
						.finally(() => {
							this.createdLoader = false;
						})
				}

			}
		},


		async submitPayment() {
			this.submitStripeLoader = true;
			this.$refs.paymentRef.submit();

			// A specific case for listening to the closure of the payment popup in an iframe.
			window.addEventListener(
				"message",
				(event) => {
					if (event.origin !== "https://js.stripe.com") return;

					let data = JSON.parse(event.data);
					if (data.message.action === 'stripe-frame-action-response' && data.message.payload.nonce.includes('CLOSE_LIGHTBOX')) {
						this.submitStripeLoader = false;
					}

					if (
						data.message.action === 'stripe-controller-action-response'
						&& data.message?.payload?.actionName === "retrievePaymentIntent"
						&& data.message?.payload?.response?.object?.last_payment_error
					) {
						this.submitStripeLoader = false;
					}

				},
				false,
			);

		},

		// photo
		resetImageUploader() {
			this.$refs.fileInput.value = null;
		},
		selectPhoto() {
			this.$refs.fileInput.click();
		},
		photoSelected(event) {
			try {
				this.selectedFile = event.target.files[0];
				const limitImageSize = 10 * 1024 * 1024;

				if (this.selectedFile.type === "image/jpeg" || this.selectedFile.type === "image/png") {
					if (this.selectedFile.size < limitImageSize) {
						const fileReader = new FileReader();
						fileReader.addEventListener("load", () => {
							this.$emit("selected_photo", this.selectedFile);
							this.selectedPhotoPreviewUrl = fileReader.result;
						});
						fileReader.readAsDataURL(this.selectedFile);
					} else {
						this.selectedPhotoPreviewUrl = null;
						this.photoLimitDialog = true;
					}
				} else {
					this.selectedPhotoPreviewUrl = null;
					this.photoLimitDialog = true;
				}
			} catch (error) {
				console.error(`uploadImage, photoSelected ${error}`);
			}
		},
		submitPaymentCallback() {
			this.submitStripeLoader = false;
		},

		hasRestrictedStatus(account) {
			const rule1 = !account.hasActiveSubscription && !account.subscription;
			const rule2 = !account.hasActiveSubscription && account.subscription && !account.subscription.canActivate;

			return rule1 || rule2;
		},

		hasExpiresStatus(account) {
			return account.subscription && account.subscription?.status && account.subscription?.status === 'past_due'
		}
	}
}
</script>

<style lang="scss">

// animation for new account modal
.height-enter-active, .height-leave-active {
	transition: all .2s ease;
	height: 124px;
	opacity: 1;
	overflow: hidden;
}

.height-enter, .height-leave-to {
	opacity: 0;
	height: 0;
	overflow: hidden;
}
</style>
