<template>
    <v-dialog
        :value="value"
        @input="$emit('input')"
        :content-class="'delete_release'"
        overlay-color="#E94E4E"
        max-width="546">

        <div class="heading">
            <h2>caution!</h2>
            <button class="close_modal" @click="dialog = false"></button>
        </div>
        <div class="description">
            You are about to delete <span class="alert_text">{{deleteAccount.name}}</span> and all of its releases
            <span class="important_text">This cannot be undone</span>
        </div>

        <div class="list" :class="{existReleases: accountReleases.length}">

            <!-- Account -->
            <div class="item account"> <!-- @click="selectAccount(deleteAccount.id)" -->
                <div class="item_info">
                    <div class="item_image">
                        <img v-if="deleteAccount.avatar && deleteAccount.avatar.contentUrl" :src="apiUrl.slice(0, -1) + deleteAccount.avatar.contentUrl" alt="">
                        <div class="default_user_avatar" v-else></div>
                    </div>
                    <div class="item_description">
                        <div class="name">{{ deleteAccount.name }}</div>
                        <div class="additional">{{ deleteAccount['@type'] }}</div>
                    </div>
                </div>
                <div class="actions">
<!--                    <div class="check_round" :tabindex="4" :class="{checked: selectedAccountId === deleteAccount.id}"></div>-->
                </div>
            </div>

            <!-- Releases -->
            <template v-if="accountReleases.length">
                <div class="item" v-for="release of accountReleases" :key="release.id + updateReleaseIndex"><!-- @click="checkDeleteRelease(release)" -->
                    <div class="item_info">
                        <div class="item_image">
                            <img v-if="release.smallImage" :src="release.smallImage" alt="">
                            <div class="default_user_avatar" v-else></div>
                        </div>
                        <div class="item_description">
                            <div class="name">{{ release.title }}</div>
                            <!--<div class="additional">12 Tracks</div>-->
                            <div class="additional">{{release.type}}</div>
                        </div>
                    </div>
                    <div class="actions">
<!--                        <div class="check_round" :tabindex="4" :class="{checked: release.delete === true}"></div>-->
                    </div>
                </div>
            </template>
        </div>
        <div class="type_the_name">
            <span class="type_description">Type the name of the account to delete</span>
            <input type="text" placeholder="Name of the account" v-model="confirmNameAccount">
        </div>
        <div class="action_modal">
            <button type="button" class="tetriatary_btn" @click="cancel" v-ripple>Cancel</button>

            <button type="button" class="delete" @click="goodbye" v-ripple v-if="deleteValidation.name === true"
                   :class="{loading: deleteReleaseLoader}"
                    @mouseover="handleHoverDeleteBtn(true)" @mouseleave="handleHoverDeleteBtn(false)">
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                {{ deleteBtnText }}
            </button>
            <div class="delete disabled" v-else>Delete</div>

        </div>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "deleteAccount",
    props: ['value', 'deleteAccount', 'accountReleases'],
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            selectedAccountId: '',
            updateReleaseIndex: 1,
            confirmNameAccount: '',
            allowDelete: false,
            hoverDeleteBtn: false,
            deleteValidation: {
                name: false,
                // account: false,
            },
            deleteReleases: [],
            deleteReleaseLoader: false,
        }
    },
    watch: {
        confirmNameAccount() {
            this.deleteValidation.name = this.confirmNameAccount === this.deleteAccount.name.trim();
        },
        // selectedAccountId() {
        //     this.deleteValidation.account = !!this.selectedAccountId;
        // },
    },
    computed: {
        ...mapGetters({
            artists: 'manageAccounts/MANAGE_ARTISTS',
        }),
        deleteBtnText() {
            return this.hoverDeleteBtn === true ? "Goodbye" : "Delete";
        },
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        ...mapActions({
            deleteArtist: 'DELETE_ARTIST',
            deleteRelease: 'DELETE_MUSIC_RELEASE',
            deleteReleaseFromCollection: 'REMOVE_RELEASE_FROM_COLLECTIONS'
        }),
        handleHoverDeleteBtn(s) {
            this.hoverDeleteBtn = s;
        },
        // selectAccount(id) {
        //     if (this.selectedAccountId === id) {
        //         this.selectedAccountId = ''
        //     } else {
        //         this.selectedAccountId = id;
        //     }
        // },
        // checkDeleteRelease(release) {
        //     release.delete = !release.delete;
        //     this.updateReleaseIndex += 1;
        //     if (release.delete === true) {
        //         this.deleteReleases.push(release)
        //     } else {
        //         let releaseIndex = this.deleteReleases.findIndex((el) => el.id === release.id);
        //         if (releaseIndex !== -1) {
        //             this.deleteReleases.splice(releaseIndex, 1);
        //         }
        //     }
        // },
        cancel() {
            this.dialog = false;
        },
        goodbye() {
            if (this.deleteValidation.name === true) {
                this.deleteReleaseLoader = true;

                // delete from list
                let artistIndex = this.artists.findIndex((artist) => artist.id === this.deleteAccount.id);
                if (artistIndex !== -1) {
                    this.artists.splice(artistIndex, 1);
                }


                // Delete Artist
                this.deleteArtist(this.deleteAccount.id)
                    .then(() => {

                    })
                    .catch(err => {
                        console.error(`deleteArtist, ${err}`)
                    })

                // Delete selected releases
                // if (this.deleteReleases.length) {
                    for (let release of this.accountReleases) {
                        this.deleteRelease(release.id)
                            .then(() => {
                                // this.deleteReleaseLoader = false;
                                // this.dialog = false;
                            })
                            .catch(err => {
                                console.error(`deleteRelease, ${err}`);
                            })
                    }
                // }

                setTimeout(() => {
                    this.deleteReleaseLoader = false;
                    this.dialog = false;
                }, 600);

            }
        }
    }
}
</script>

<style scoped>

</style>