<template>
    <div class="type" :class="{ 'restricted': isRestricted()}">

        <!-- Restricted Expired -->
        <template v-if="status === 'expired'">
            Expired
        </template>

        <!-- Restricted Expires -->
        <template v-if="status === 'expires'">
            Billing issues
        </template>
    </div>
</template>

<script>

export default {
    name: "SubscriptionStatus",
    props: ['subscription'],
    data() {
        return {
            status: null,
        }
    },
    created() {
        this.checkStatus();
    },
    methods: {
        isRestricted() {
            return ['expired', 'expires'].includes(this.status);
        },
        isExpiresDate() {
            return (this.subscription?.currentPeriodEnd && this.subscription?.issue?.type === 'billing_update')
        },
        checkStatus() {
            if (this.subscription?.status && this.subscription?.status === 'past_due') {
                this.status = 'expires'

                return;
            }

            this.status = 'expired';
        },
    }
}
</script>

<style scoped>

</style>
